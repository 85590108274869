body {
    font-family: 'Poppins', sans-serif;
    background-color: #f8f8fb;
    color: #686868;
    min-height: 100vh;
    font-size: 18px;
    a{
        cursor: pointer;
    }
    .btn{
        background-color: #bf1522;
        border-radius: 36px;
        padding: 8px 10px 8px 10px;
        color: white;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        margin-top: 10px;
        white-space: nowrap;
        i{
            margin-left: 15px;
            margin-right: 5px;
        }
    }
    strong,b{
        color: black;
    }
    h1,h2,h3,h4{
        line-height: 40px;
        font-size: 37px;
        color: #bf1522;
    }
    .row.reverese{
        flex-direction: row-reverse;
    }
    ul{
        margin-block-start: 20px;
        margin-block-end: 20px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 20px;
    }
    .whiteBg{
        background-color: white;
    }
    .mobileNav{
        font-size: 30px;
        color: black;
    }
    #head{
        background-color: white;
        .head{
            ul{
                li{
                    display: inline-block;
                    a{
                        color: #282828;
                        text-decoration: none;
                        font-size: 14px;
                        display: block;
                        &:hover,&.active{
                            border-bottom: 1px solid #282828;
                        }
                    }
                }
            }
            @media screen and (max-width:989px){
                ul{
                    /*display: none;*/
                    li{
                        display: block;
                    }
                }
                &.active{
                    ul{
                        display: block;
                    }
                }
            }
        }
    }
    .title{
        a{
            @extend .btn;
        }
    }
    .welcome{
        .text{

        }
    }
    .services{
        .service{
            font-size: 15px;
            .row{
                .icons{
                    font-size: 30px;
                    text-align: center;
                    color: #bf1522;
                }
                .text{
                    p{
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                }
            }
        }
    }
    #footer{
        @extend .whiteBg;
        text-align: center;
        padding: 25px 0px 25px 0px;
        p{
            font-size: 14px;
            color: #88939b;
            a{
                color: #c71a2a;
                text-decoration: none;
                border-bottom: 1px solid #c71a2a;
                padding-bottom: 3px;
            }
        }
    }
    #partners{
        background-color: white;
        .title{
            text-align: center;
            h2{
                float: none;
            }
        }
        .partner{
            img{
                opacity: 1;
            }
        }
    }
    #contacts{
        .contactForm{
            .form-group{
                input,textarea{
                    color: black;
                    border: none;
                    -webkit-box-shadow: 0px 0px 18px 0px rgba(126,126,126,.18);
                    -moz-box-shadow: 0px 0px 18px 0px rgba(126,126,126,.18);
                    box-shadow: 0px 0px 18px 0px rgba(126,126,126,.18);
                    font-size: 14px;
                }
                .btn{
                    border: none;
                    line-height: 30px;
                }
                p.help-block{
                    ul{
                        display: none;
                    }
                }
                &.error{
                    input,textarea{
                        -webkit-box-shadow: 0px 0px 18px 0px rgba(191, 21, 34,.22);
                        -moz-box-shadow: 0px 0px 18px 0px rgba(191, 21, 34,.22);
                        box-shadow: 0px 0px 18px 0px rgba(191, 21, 34,.22);
                        color: rgba(191, 21, 34,1);
                        &::-webkit-input-placeholder {
                          color: rgba(191, 21, 34,1);
                        }
                        &:-ms-input-placeholder {
                          color: rgba(191, 21, 34,1);
                        }
                        &::placeholder {
                           color: rgba(191, 21, 34,1);
                        }
                    }
                }
                &.error{
                    &.checkbox{
                        color: rgba(191, 21, 34,1);
                    }
                }
            }
        }
        .contact{
            background-color: #bf1522;
            color: white;
            z-index: 100;
            position: relative;
            &::after{
                content: "";
                width: 50%;
                height: 100%;
                background-color: #bf1522;
                position: absolute;
                top: 0px;
                right: -50%;
                z-index: -1;
            }
            .block{
                h2{
                    color: white;
                }
                h4{
                    margin: 0px;
                    padding: 0px;
                    line-height: 22px;
                    color: white;
                    text-decoration: none;
                    font-size: 18px;
                }
                span{
                    font-size: 12px;
                    display: block;
                }
                p{
                    font-size: 13px;
                }
                hr{
                    border: none;
                    border-bottom: 1px solid white;
                }
            }
            .cont{
                font-size: 14px;
                line-height: 24px;
                strong{
                    color: white;
                }
                a{
                    color: white;
                    text-decoration: none;
                    margin-left: 10px;
                }
                span{
                    display: inline-block;
                    width: 80px;
                }
            }
        }
    }
}
